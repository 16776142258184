import React from "react";
import { Link } from "gatsby";
import BalanceCheckButton from "@components/BalanceCheckButton";

const ButtonBar = () => (
  <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
    <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
      <Link to="/store" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-primary bg-white hover:bg-red sm:px-8">
        Buy Gift Cards
      </Link>
      <BalanceCheckButton />
    </div>
  </div>
);

export default ButtonBar;
